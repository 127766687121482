
















































































































import {Component, Prop} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import Notifications from './mixins/Notifications'
import ToolTip from '@/components/custom/ToolTip.vue'
import {channelsStore} from '@/store/modules/channels'
import DialogConfirm from '@/components/DialogConfirm.vue'
import DialogSubscribers from '@/components/DialogSubscribers.vue'
import DialogCreateChannel from '@/components/channels/DialogCreateChannel.vue'
import PopupMenu from '@/components/PopupMenu.vue'
import {copyTextToClipboard} from '@/utils/helpers';
import {profileStore} from '@/store/modules/profile';

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'channel-info',
  components: {DialogConfirm, ToolTip, PopupMenu, DialogCreateChannel, DialogSubscribers},
  filters: {}
})
export default class ChannelInfo extends mixins(Notifications) {
  @Prop({default: false}) value!: boolean;

  public showEditChannelDialog: boolean = false
  public showSubscribersDialog: boolean = false

  dialogConfirm: boolean = false
  dialogConfirmTitle: string = ''
  dialogConfirmText: string = ''
  dialogConfirmBtn: string = ''
  onConfirm?: any;

  get t2bUser() {
    return profileStore.t2bUser
  }

  get isAdmin() {
    return profileStore.isAdmin
  }

  get isActive() {
    return !channelsStore.selectedChannel?.disabled
  }

  get hasSubscribers() {
    return this.membersCount > 0
  }

  get show() {
    return this.value;
  }

  get channel() {
    return channelsStore.selectedChannel
  }

  get name() {
    return this.channel?.name
  }

  get description() {
    return this.channel ? this.channel.description : ''
  }

  get imageUrl() {
    return this.channel?.imageUrl ?
        this.channel?.imageUrl :
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
  }

  get membersCount() {
    return this.channel?.memberIDs?.length || 0
  }

  set show(value) {
    this.$emit('input', value);
  }

  get access() {
    if (this.isAdmin) {
      return true
    }
    if (this.channel?.createdBy.id === this.t2bUser.id) {
      return true
    }
    return false
  }

  private onDeleteChannel() {
    this.dialogConfirmTitle = 'Do you want to delete this channel?'
    this.dialogConfirmText = 'Warning: This action can’t be undone.'
    this.dialogConfirmBtn = 'Yes, delete'
    this.onConfirm = async () => {
      this.resetAlert()
      const ok = await channelsStore.deleteSelectedChannel()
      if (ok) {
        this.showInfo('Channel has been deleted')
        await this.$router.push({name: 'channels'})
      } else {
        this.showIssue('Failed deleting channel')
      }
    }
    this.dialogConfirm = true
  }

  private onActivateDisableChannel() {
    this.dialogConfirmTitle = this.isActive ? 'Do you want to disable this channel?'
        : 'Do you want to activate this channel?'
    this.dialogConfirmBtn = this.isActive ? 'Yes, disable' : 'Yes, activate'
    this.onConfirm = async () => {
      this.resetAlert()
      const ok = await channelsStore.activateDisableSelectedChannel()
      if (ok) {
        this.showInfo(this.isActive ? 'Channel has been activated' : 'Channel has been disabled')
      } else {
        this.showIssue('Failed to update the channel')
      }
    }
    this.dialogConfirm = true
  }

  private resetAlert() {
    this.dialogConfirm = false
    this.dialogConfirmTitle = ''
    this.dialogConfirmText = ''
    this.dialogConfirmBtn = ''
  }

  public onClose() {
    this.show = false
  }

  private channelMenuItems() {
    return [
      {id: 1, title: 'Edit'},
    ];
  }

  private onChannelMenuItemSelected(menuItem) {
    switch (menuItem.title) {
      case 'Edit':
        this.showEditChannelDialog = true
        break;
      default:
    }
  }

  private async onShareLink() {
    try {
      const shortLink = await channelsStore.shareChannel()
      await copyTextToClipboard(shortLink)
      this.showInfo('Channel link copied to clipboard', 'chain')
    } catch (err: any) {
      this.showIssue(err.message)
    }
  }
}
