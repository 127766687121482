import store from '@/store';
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {applicationStore} from '@/store/modules/application';
import {addDoc, arrayRemove, arrayUnion, onSnapshot, orderBy, query, updateDoc} from 'firebase/firestore';
import {profileStore} from '@/store/modules/profile';
import {AssociateAccount, UserAccount} from '@/domain/model/types';
import {businessCustomer, businessCustomerNotes, userReports} from '@/data/firebase';

@Module({name: 'customers-store', dynamic: true, store})
export default class CustomersStore extends VuexModule {
  private _notes: any[] = []
  private _notesLoaded: boolean = false

  get notes() {
    return this._notes
  }

  get notesLoaded() {
    return this._notesLoaded
  }

  @Mutation
  public setNotesLoaded(loaded: boolean) {
    this._notesLoaded = loaded
  }

  @Mutation
  public setNotes(notes: []) {
    this._notes = notes
  }

  @Mutation
  public addNote(note: any) {
    this._notes.push(note)
  }

  @Mutation
  public updateNote(note: any) {
    this._notes.splice(this._notes.findIndex((item) => item.id === note.id), 1, note);
  }

  @Mutation
  public removeNote(note: any) {
    this._notes.splice(this._notes.findIndex((item) => item.id === note.id), 1);
  }

  @Action
  public async toggleVipStatus(customer) {
    if (!customer) {
      return;
    }
    try {
      const businessId = applicationStore.businessId;
      await updateDoc(businessCustomer(businessId!, customer.id), 'vip', !customer.vip);
    } catch (err) {
      console.error(err);
    }
  }

  @Action
  public async toggleSharedStatus(customer) {
    if (!customer) {
      return;
    }
    try {
      const businessId = applicationStore.businessId;
      const shared = !customer.shared;
      await updateDoc(businessCustomer(businessId!, customer.id), 'shared', shared);
      if (!shared) {
        this.context.commit('setSelectedCustomer', null)
      }
    } catch (err) {
      console.error(err);
    }
  }

  @Action
  public async addToPersonalContacts(customerId: string) {
    try {
      const userId = profileStore.t2bUser!.id;
      const businessId = applicationStore.businessId;
      await updateDoc(businessCustomer(businessId!, customerId), 'personal', arrayUnion(userId));
    } catch (err) {
      console.error(err);
    }
  }

  @Action
  public async removeFromPersonalContacts(customerId: string) {
    try {
      const userId = profileStore.t2bUser!.id;
      const businessId = applicationStore.businessId;
      await updateDoc(businessCustomer(businessId!, customerId), 'personal', arrayRemove(userId));
      this.context.commit('setSelectedCustomer', null)
    } catch (err) {
      console.error(err);
    }
  }

  @Action
  public async loadNotes(customerId: string) {
    const businessId = applicationStore.businessId;
    if (!businessId) {
      return () => null
    }
    this.setNotesLoaded(false)
    this.setNotes([])
    const notesQuery = query(
      businessCustomerNotes(businessId, customerId),
      orderBy('createdDate', 'desc'))
    return onSnapshot(notesQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const data = change.doc.data();
          const note = {id: change.doc.id, ...data};
          switch (change.type) {
            case 'added': {
              this.addNote(note);
              break;
            }
            case 'modified': {
              this.updateNote(note);
              break;
            }
            case 'removed': {
              this.removeNote(note)
              break;
            }
            default:
          }
        });
        this.setNotesLoaded(true)
      },
      (error) => console.log(error));
  }

  @Action
  public async reportCustomer(data: { customer: UserAccount, message: string }) {
    const associate: AssociateAccount = profileStore.t2bUser;
    await addDoc(userReports, {
      reporter: {
        name: associate.fullName,
        uid: associate.id,
        photoUrl: associate.photoUrl?.thumbnail || null
      },
      reporting: {
        name: data.customer.fullName,
        uid: data.customer.id,
        photoUrl: data.customer.photoUrl?.thumbnail || null
      },
      message: data.message
    })
  }
}

export const customersStore = getModule(CustomersStore);
