

















































































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator'
import Notifications from '@/components/mixins/Notifications'
import {Unsubscribe} from 'firebase/firestore'
import DialogConfirm from '@/components/DialogConfirm.vue'
import DialogSendContact from '@/components/chat/DialogSendContact.vue'
import DialogSendLocation from '@/components/chat/DialogSendLocation.vue'
import ToolTip from '@/components/custom/ToolTip.vue'
import ChannelInfo from '@/components/ChannelInfo.vue'
import EmptyState from './EmptyState.vue'
import {mixins} from 'vue-class-component'
import {channelsStore} from '@/store/modules/channels'
import {profileStore} from '@/store/modules/profile'
import StandardMessage from '@/components/messages/StandardMessage.vue';
import ImageMessage from '@/components/messages/ImageMessage.vue';
import LocationMessage from '@/components/messages/LocationMessage.vue';
import SystemMessage from '@/components/messages/SystemMessage.vue';
import BusinessCardMessage from '@/components/messages/BusinessCardMessage.vue';
import {copyTextToClipboard, downloadFile, formatSectionDate} from '@/utils/helpers';
import NoMessages from '@/components/NoMessages.vue';
import {Action, Getter} from 'vuex-class';
import ContactInfo2 from '@/components/ContactInfo2.vue';
import CustomerProfile from '@/components/business/customers/CustomerProfile.vue';
import {
  ACTION_MESSAGE_DOWNLOAD,
  ACTION_MESSAGE_COPY,
  ACTION_MESSAGE_DELETE,
  ACTION_MESSAGE_EDIT,
  ACTION_MESSAGE_MORE
} from '@/common/context-menu-actions';
import DialogDeleteMessages from '@/components/DialogDeleteMessages.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave'
]);
@Component({
  name: 'channel-detail',
  components: {
    DialogDeleteMessages,
    CustomerProfile,
    ContactInfo2,
    BusinessCardMessage,
    SystemMessage,
    LocationMessage,
    ImageMessage,
    StandardMessage,
    ToolTip,
    ChannelInfo,
    EmptyState,
    DialogConfirm,
    DialogSendContact,
    DialogSendLocation,
    NoMessages
  },
  filters: {formatSectionDate}
})
export default class ChannelDetail extends mixins(Notifications) {
  @Prop() channelId?: string;

  private viewContactId: string | null = null
  private channelMessageText: string = ''
  private drawerChannelInfo: boolean = false;
  private unsubscribeChannel: Unsubscribe | null = null
  private unsubscribeMessages: Unsubscribe | null = null
  private showDialogSendContact: boolean = false
  private dialogConfirmAction: boolean = false
  private dialogConfirmActionTitle: string = ''
  private dialogConfirmActionMessage: string = ''
  private dialogConfirmActionCancelShow: boolean = true
  private onDialogConfirmActionPositiveCallback: any
  private onDialogConfirmCancel: any
  private confirm: string = ''
  private error: any = {message: null}
  private file: any = null
  private moreOption: boolean = false
  private messageToEdit: any = null
  private showDeleteMessagesDialog: boolean = false
  private showMap: boolean = false
  private location: any = null

  @Getter selectedLocation

  get channel() {
    return channelsStore.selectedChannel
  }

  get messageItems() {
    return channelsStore.channelMessages
  }

  get messagesLoaded() {
    return channelsStore.messagesLoaded
  }

  get name() {
    return this.channel?.name
  }

  get imageUrl() {
    return this.channel?.imageUrl ?
        this.channel?.imageUrl :
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
  }

  get membersCount() {
    return this.channel?.memberIDs?.length || 0
  }

  get disabled() {
    return !!this.channel?.disabled
  }

  get isEmpty() {
    return !this.messageItems.length
  }

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get userId() {
    return this.t2bUser?.id;
  }

  get selectedMessages() {
    return this.messageItems.filter(message => message.selected);
  }

  get selectedMessagesCount() {
    return this.selectedMessages.length;
  }

  get messageSelected() {
    return this.messageToEdit
  }

  @Action resetSelectedLocation;

  unsubscribeAll() {
    if (this.unsubscribeChannel) {
      try {
        this.unsubscribeChannel();
      } catch (e) {
      }
      this.unsubscribeChannel = null;
    }
    if (this.unsubscribeMessages) {
      try {
        this.unsubscribeMessages();
      } catch (e) {
      }
      this.unsubscribeMessages = null;
    }
  }

  async init(channelId?: string) {
    if (!channelId) {
      return
    }
    this.unsubscribeChannel = await channelsStore.loadChannel(channelId)
    this.unsubscribeMessages = await channelsStore.loadChannelMessages(channelId);
    await channelsStore.loadChannelMembers(channelId)
  }

  private async onSendContact(contact: any) {
    try {
      await channelsStore.sendChannelContactMessage(contact)
      this.showInfo('Contact has been sent')
    } catch (error: any) {
      this.showIssue(error.message)
    }
  }

  private viewContact(contact: any) {
    if (contact.type <= 2) {
      this.viewContactId = contact.id
    }
  }

  public onChannelInfo() {
    this.drawerChannelInfo = !this.drawerChannelInfo
  }

  private onMediaFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
      if (!this.file.type.match('image.*')) {
        this.error = {
          message: 'You can only send images',
          timeout: 2000
        };
        return;
      }

      this.dialogConfirmActionTitle = 'Do you want send image ?';
      this.dialogConfirmActionMessage = '';
      this.onDialogConfirmCancel = () => {
        this.file = null
      }
      this.onDialogConfirmActionPositiveCallback = async () => {
        this.dialogConfirmAction = false;
        // Check if the user is signed-in
        if (this.t2bUser) {
          const screenWindow: any = window;
          const _URL = screenWindow.URL || screenWindow.webkitURL;
          const img = new Image();
          img.onload = async () => {
            await channelsStore.sendChannelImageMessage({
              src: img.src,
              file: this.file,
              width: img.width,
              height: img.height
            })
            this.file = null
          };
          img.onerror = () => {
            this.showIssue(`not a valid file: ${this.file.type}`);
          };
          img.src = _URL.createObjectURL(this.file);
        }
      }
      this.confirm = 'Yes, send'
      this.dialogConfirmAction = true;
    }
  }

  private closeLocation() {
    this.showMap = false
    this.location = null
    this.resetSelectedLocation()
  }

  private showLocation(geopoint: any) {
    this.location = {lat: geopoint.latitude, lng: geopoint.longitude}
    this.showMap = true
  }

  private async sendLocation() {
    if (!!this.selectedLocation) {
      await channelsStore.sendChannelLocationMessage()
      this.closeLocation();
    }
  }

  private async sendMessage(event) {
    const message = this.channelMessageText.trim()
    if ((event.key === 'Enter' && event.shiftKey) ||
        (event.key === 'Enter' && event.ctrlKey)) {
      return;
    }
    if (message === '') {
      return
    }
    if (this.messageToEdit !== null) {
      await channelsStore.editChannelTextMessage({id: this.messageToEdit.id, text: message})
      this.channelMessageText = ''
      this.messageToEdit = null;
      return;
    }
    await channelsStore.sendChannelTextMessage(message)
    this.channelMessageText = ''
  }

  private downloadImage(message) {
    const { name } = message.image
    const storageUri = `/businesses/${this.t2bUser.business?.id}/channels/${this.channelId}/channelMessages/${message.id}/${name}`
    downloadFile({name, storageUri})
  }

  private clearSelectedMessage() {
    this.messageToEdit = null
  }

  async onOptionItemClicked(action, messageView) {
    const message = messageView.data;
    switch (action.type) {
      case ACTION_MESSAGE_DOWNLOAD: {
        this.downloadImage(message);
        break;
      }
      case ACTION_MESSAGE_EDIT: {
        this.channelMessageText = message.text;
        this.messageToEdit = message;
        break;
      }
      case ACTION_MESSAGE_COPY: {
        try {
          await copyTextToClipboard(message.text);
          this.showInfo('Message has been copied');
        } catch (err) {
          this.showIssue('Failed to copy message');
        }
        break;
      }
      case ACTION_MESSAGE_MORE: {
        this.moreOption = true;
        break;
      }
      case ACTION_MESSAGE_DELETE: {
        messageView.selected = true
        this.showDeleteMessagesDialog = true
        break;
      }
      default:
    }
  }

  private onDeleteMessages() {
    this.showDeleteMessagesDialog = true
  }

  private async deleteSelectedMessages(_: boolean) {
    await channelsStore.deleteMessages(this.selectedMessages?.map((message) => message.data.id))
    this.onCancelSelection()
  }

  private onCancelSelection() {
    this.moreOption = false
    this.selectedMessages.forEach((message) => message.selected = false)
  }

  private onChooseImage() {
    const fileField: HTMLInputElement = this.$refs.chooseImageForChannel as HTMLInputElement
    fileField.value = ''
    fileField.click()
  }

  @Watch('messagesLoaded')
  onMessageLoadedEvent(value, oldValue) {
    if (!value) {
      return;
    }
    const callback = (context) => {
      return function() {
        const messagesLayout: any = context.$refs.messagesLayout;
        if (messagesLayout) {
          messagesLayout.scrollTop = messagesLayout.scrollHeight;
          messagesLayout.style.visibility = 'visible';
          messagesLayout.style.scrollBehavior = 'smooth';
        }
        channelsStore.setMessagesLoaded(false);
      }
    };
    setTimeout(callback(this), 250);
  }

  private async beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.init(to.params.channelId)
    });
  }

  private async beforeRouteUpdate(to, from, next) {
    this.viewContactId = null
    this.unsubscribeAll()
    await this.init(to.params.channelId)
    next();
  }

  private async beforeRouteLeave(to, from, next) {
    this.viewContactId = null
    this.unsubscribeAll()
    next()
  }
}
