






























































import {Component, Prop, Vue} from 'vue-property-decorator';
import GoogleMap from '@/components/maps/GoogleMap.vue';
import GoogleMapAutocomplete from "@/components/maps/GoogleMapAutocomplete.vue";
import {Getter} from 'vuex-class';

@Component({
  name: 'dialog-send-location',
  components: {
    GoogleMap, GoogleMapAutocomplete
  }})
export default class DialogSendLocation extends Vue {
  @Prop() value?: boolean;
  @Prop() location?: any;

  currentLocation: boolean = false
  zoom: number = 15

  @Getter selectedLocation
  @Getter selectedLocationAddress

  get show() {
    return !!this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
  }

  get title() {
    return !this.location ? 'Send location' : 'Show location'
  }

  onSetCurrentLocation() {
    this.currentLocation = true
    this.$nextTick(() => {
      this.currentLocation = false
    })
  }

  onClose() {
    this.$emit('on-close')
    this.show = false
  }
}
