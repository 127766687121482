




























































































































































































































































































































































































































































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import StandardMessage from '@/components/messages/StandardMessage.vue';
import ReplyMessage from '@/components/messages/ReplyMessage.vue';
import ImageMessage from '@/components/messages/ImageMessage.vue';
import SystemMessage from '@/components/messages/SystemMessage.vue';
import PersonAddedToChatMessage from '@/components/messages/PersonAddedToChatMessage.vue';
import RequestPersonalDataMessage from '@/components/messages/RequestPersonalDataMessage.vue';
import PersonalDataResultMessage from '@/components/messages/PersonalDataResultMessage.vue';
import LocationMessage from '@/components/messages/LocationMessage.vue';
import RequestAppointment from '@/components/messages/RequestAppointment.vue';
import ChatInfo from '@/components/ChatInfo.vue';
import NoMessages from '@/components/NoMessages.vue';
import BusinessCardMessage from '@/components/messages/BusinessCardMessage.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import ForwardMessage from '@/components/messages/ForwardMessage.vue';
import ContactsList from '@/components/ContactsList.vue';
import GoogleMap from '@/components/maps/GoogleMap.vue';
import constants from '@/common/constants';
import {
  compareMembersFn,
  copyTextToClipboard,
  downloadFile,
  firstWord,
  twoChars
} from '@/utils/helpers';
import {
  ACTION_MESSAGE_COPY,
  ACTION_MESSAGE_DELETE,
  ACTION_MESSAGE_EDIT,
  ACTION_MESSAGE_FORWARD,
  ACTION_MESSAGE_MORE,
  ACTION_MESSAGE_PIN_NOTE,
  ACTION_MESSAGE_REPLY,
  ACTION_MESSAGE_DOWNLOAD
} from '@/common/context-menu-actions';
import {Action, Getter, Mutation} from 'vuex-class';
import {Timestamp, Unsubscribe} from 'firebase/firestore';
import DialogConfirm from '@/components/DialogConfirm.vue';

import DialogForwardCase from '@/components/DialogForwardCase.vue';
import {applicationStore} from '@/store/modules/application';
import SearchView from '@/components/SearchView.vue';
import {functions} from '@/plugins/firebase.init';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {chatStore} from '@/store/modules/chat/ChatStore';
import DialogDeleteMessages from '@/components/DialogDeleteMessages.vue';
import {profileStore} from '@/store/modules/profile';
import DialogForwardMessage from '@/components/chat/DialogForwardMessage.vue';
import DialogSendContact from '@/components/chat/DialogSendContact.vue';
import {videoCallStore} from '@/store/modules/video/videoCallStore';
import DialogSendLocation from '@/components/chat/DialogSendLocation.vue';
import DialogScheduleAppointment from '@/components/directory/customer/DialogScheduleAppointment.vue';
import DialogDetailSchedule from '@/components/chats/DialogDetailSchedule.vue';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from 'vue-class-component';
import {requestsStore} from '@/store/modules/requests/RequestsStore';
import ContactInfo2 from '@/components/ContactInfo2.vue';
import CustomerProfile from '@/components/business/customers/CustomerProfile.vue';
import {notificationsStore} from '@/store/modules/notifications';
import ToolTip from '@/components/custom/ToolTip.vue'
import {tourStore} from '@/store/modules/tour';
import {httpsCallable} from 'firebase/functions';
import {MessageUI} from '@/domain/model/types';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate'
]);

@Component({
  name: 'chat-screen2',
  components: {
    CustomerProfile,
    ContactInfo2,
    DialogConfirm,
    DialogSendLocation,
    DialogSendContact,
    DialogForwardMessage,
    DialogDeleteMessages,
    DialogScheduleAppointment,
    DialogDetailSchedule,
    AvatarWithStatus,
    SearchView,
    StandardMessage,
    ReplyMessage,
    ImageMessage,
    SystemMessage,
    PersonAddedToChatMessage,
    RequestPersonalDataMessage,
    PersonalDataResultMessage,
    LocationMessage,
    ChatInfo,
    NoMessages,
    BusinessCardMessage,
    GroupAvatar,
    ForwardMessage,
    ContactsList,
    GoogleMap,
    DialogForwardCase,
    RequestAppointment,
    ToolTip
  }
})
export default class ChatScreen2 extends mixins(Vue, Notifications) {
  @Prop() chatId?: string;
  @Prop() type?: string;
  @Prop() subtype?: string;
  @Prop({default: false}) newChat?: boolean;

  viewContactId: string | null = null
  viewCustomerId: string | null = null

  windows: any = {
    chat: 0
  };

  activeWindow: number = this.windows.chat;
  chatMessageText: string = '';
  chatMessageLoading: boolean = false;
  messageToReplay: any = null;
  messageToEdit: any = null;
  messageToDelete: any = null;
  moreOption: boolean = false;
  toolbarTitle: string = '';
  drawerChatInfo: boolean = false;
  unsubscribeMessages: Unsubscribe | null = null;
  unsubscribeTextSession: Unsubscribe | null = null;
  unsubscribeCaseNotes: Unsubscribe | null = null;
  unsubscribeCustomerNotes: Unsubscribe | null = null;
  error: any = {message: null};
  showError: boolean = false;
  personalOnly: boolean = false;
  showDialogSendContact: boolean = false;
  disableRequestControls: boolean = false;
  isCaseInfoActive: boolean = false;
  acceptRequestSelected: boolean = false;
  rejectRequestSelected: boolean = false;
  blockCustomerSelected: boolean = false;
  forwardRequestSelected: boolean = false;
  forwardTrigger: boolean = true
  selectedConversation: any = null;
  dialogForwardMessage: boolean = false;
  dialogConfirmAction: boolean = false;
  dialogConfirmActionTitle: string = '';
  dialogConfirmActionMessage: string = '';
  dialogConfirmActionCancelShow: boolean = true
  onDialogConfirmActionPositiveCallback: any;
  onDialogConfirmCancel: any = null;
  confirm: string = ''
  //todo: refactore
  textColor: string = ''
  //todo: refactore
  btnColor: string = 'error'
  showMap: boolean = false;
  fullscreenMap: boolean = false
  location: any = null;
  chatInfoWindow: string | null = 'info';

  titleForwardCaseDialog: string | null = '';
  showForwardCaseDialog: boolean = false;
  showDeleteMessagesDialog: boolean = false;
  showForwardMessageDialog: boolean = false;
  showDialogScheduleAnAppointment: boolean = false
  showDialogDetailSchedule: boolean = false;

  srcMessages: any[] = [];

  audioSelected: boolean = false;
  file: any = null

  @Getter selectedTextSession;
  @Getter messages;
  @Getter selectedLocation;
  @Getter messagesLoaded;
  @Getter isLoadingMessages;
  @Getter showFullConversation;
  @Getter getSelectedAppointment;

  @Mutation setFullConversation;
  @Mutation setSelectedCustomer;

  @Action closeConversation;
  @Action loadChat;
  @Action loadMessages;
  @Action sendImageMessage;
  @Action sendReplyMessage;
  @Action editMessage;
  @Action forwardMessage;
  @Action sendTextMessage;
  @Action forwardCase;
  @Action rejectRequest;
  @Action acceptRequest;
  @Action caseClose;
  @Action sendContact;
  @Action requestPersonalData;
  @Action resetSelectedLocation;
  @Action sendLocationMessage;
  @Action resetMessagesLoaded;
  @Action unblockContact;
  @Action blockContact;
  @Action createInnerChat;
  @Action requestAppointment;

  @Watch('chatMessageText')
  async onChatMessageChanged(after, before) {
    if (!before && !!after) {
      await chatStore.updateTyping({chatId: this.chatId, typing: true})
      return
    }
    if (!!before && !after) {
      await chatStore.updateTyping({chatId: this.chatId, typing: false})
    }
  }

  @Watch('messagesLoaded')
  onMessageLoadedEvent(value, oldValue) {
    if (!value) {
      return;
    }
    const callback = (context) => {
      return function() {
        const messagesLayout: any = context.$refs.messagesLayout;
        if (messagesLayout) {
          messagesLayout.scrollTop = messagesLayout.scrollHeight;
          messagesLayout.style.visibility = 'visible';
          messagesLayout.style.scrollBehavior = 'smooth';
        }
        context.resetMessagesLoaded();
      }
    };
    setTimeout(callback(this), 250);
  }

  @Watch('selectedTextSession')
  async onSelectedChatChanged(newChat, oldChat) {
    if (newChat?.id === oldChat?.id) {
      return
    }
    let unreadCount = 0
    if (!!newChat?.unread && newChat.unread[this.userId!] > 0) {
      unreadCount = newChat.unread[this.userId!]
    }
    if (unreadCount > 0) {
      //todo: replace with Go API
      const resetCounter = httpsCallable(functions, 'apiDbTextSessionsResetCounter')
      try {
        await resetCounter({
          userId: this.userId,
          textSessionId: newChat.id
        })
      } catch (err) {
        console.error(`apiDbTextSessionsResetCounter: ${err}`)
      }
    }
  }

  @Watch('undoProcessed')
  onChangUndoProcessed() {
    this.switchRequestControl()
  }

  get key() {
    return `${this.type}:${this.subtype}`
  }

  get messageItems(): MessageUI[] {
    if (this.showFullConversation) {
      return this.messages
    }
    const millis = this.caseOpenDate?.toMillis() || 0;
    return this.messages.filter((message: MessageUI) => message.createdAtMillis >= millis)
  }

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get businessId() {
    return applicationStore.businessId;
  }

  get withInput() {
    return this.isChatMember && (this.isRequest || this.isAccepted || this.isRejected || this.isInnerChat || !!this.newChat);
  }

  get messagesViewStyle() {
    if (this.withInput) {
      return {
        maxHeight: '83vh',
        height: '83vh'
      };
    } else {
      return {
        maxHeight: '94vh',
        height: '94vh'
      };
    }
  }

  get userId() {
    return this.t2bUser?.id;
  }

  get denseInput() {
    return this.messageSelected || this.audioSelected;
  }

  get customer() {
    return this.selectedTextSession?.customer
  }

  get messageSelected() {
    return this.messageToReplay || this.messageToEdit || this.messageToForward;
  }

  get selectedMessages() {
    return this.messageItems.filter(message => message.selected);
  }

  get selectedMessagesCount() {
    return this.selectedMessages.length;
  }

  get isActiveChat() {
    return this.selectedTextSession && this.selectedTextSession.type === 4;
  }

  get isInnerChat() {
    return this.selectedTextSession && this.selectedTextSession.type === 3;
  }

  get isAccepted() {
    return (
        this.selectedTextSession &&
        this.selectedTextSession.case &&
        this.selectedTextSession.case.status === 2
    );
  }

  get isRequest() {
    return this.selectedTextSession?.case?.status === 1;
  }

  get isRejected() {
    return this.selectedTextSession?.case?.status === 3;
  }

  get isGroupChat() {
    return this.selectedTextSession?.subtype > 2;
  }

  get isChatMember() {
    return !!this.selectedTextSession?.memberIDs?.includes(this.userId)
  }

  get isAdmin() {
    return profileStore.isAdmin
  }

  get isCustomerChat() {
    return this.selectedTextSession?.customer instanceof Object
  }

  get isBlockedUser() {
    return Object.values(this.selectedTextSession?.blockList || {}).includes(this.userId!)
  }

  get hasBlockedUser() {
    return !!(this.selectedTextSession?.blockList || {})[this.userId!]
  }

  get extendedGroupChat() {
    return !!this.selectedTextSession && this.selectedTextSession.subtype === 3;
  }

  get title() {
    return this.selectedTextSession && this.selectedTextSession.title
  }

  get chatTitle() {
    if (!!this.title) {
      return this.title
    }
    if (this.isActiveChat) {
      return this.selectedTextSession?.customer && this.selectedTextSession.customer.name
    }
    if (this.extendedGroupChat) {
      return Object.values(this.selectedTextSession.members).map((item: any) => item.name).sort().join(', ')
    }
    return this.selectedTextSession?.from.uid === this.userId
        ? this.selectedTextSession?.to.name
        : this.selectedTextSession?.from.name
  }

  get allowShowDOB() {
    return !!this.selectedTextSession?.customer?.permissions?.viewDOB
  }

  get dob() {
    return this.isActiveChat && this.allowShowDOB ? this.selectedTextSession?.customer?.dob : null
  }

  get checkLastPermissionRequest() {
    const lastMassage = this.messageItems.slice(-1)[0]
    return lastMassage.data.type === constants.MESSAGE_TYPE_REQUEST_PERSONAL_DATA
        || lastMassage.data.type === constants.MESSAGE_TYPE_PERSONA_DATA_RESULT
  }

  get checkPermissionRequest() {
    return this.isActiveChat && !(this.customer.permissions?.viewEmail
        || this.customer.permissions?.viewPhone
        || this.customer.permissions?.viewDOB
    )
  }

  get imageUrl() {
    if (!this.selectedTextSession) {
      return null
    }
    if (this.isActiveChat) {
      const customer = this.selectedTextSession.customer;
      const photoUrl = customer && customer.photoUrl;
      return photoUrl && photoUrl.normal
    }
    const fromId = this.selectedTextSession.from && this.selectedTextSession.from.uid;
    const toId = this.selectedTextSession.to && this.selectedTextSession.to.uid;
    const uid = fromId === this.userId ? toId : fromId
    const member = this.selectedTextSession.members && this.selectedTextSession.members[uid];
    return (member && member.photoUrl) ? member.photoUrl.normal : null;
  }

  get imagesUrls() {
    if (!this.selectedTextSession) {
      return null
    }
    return Object.values(this.selectedTextSession.members)
        .sort(compareMembersFn)
        .filter((item: any) => item && !!item.photoUrl)
        .map((item: any) => item.photoUrl.normal);
  }

  get hasHistory() {
    return this.selectedTextSession
        && this.selectedTextSession.cases > 1
  }

  get caseOpenDate() {
    return this.selectedTextSession
        && this.selectedTextSession.case
        && this.selectedTextSession.case.openedDate
  }

  get firstMessageCreatedDate() {
    return this.messages[0].data.createdDate
  }

  get dispatchForCode() {
    return function(event, callback) {
      let code;

      if (event.key !== undefined) {
        code = event.key;
      } else if (event.keyIdentifier !== undefined) {
        code = event.keyIdentifier;
      } else if (event.keyCode !== undefined) {
        code = event.keyCode;
      }
      callback(code);
    };
  }

  get canJoinVideoCall() {
    return !!this.selectedTextSession?.videoCall
  }

  get messageToForward() {
    return chatStore.messageToForward
  }

  get undo() {
    return notificationsStore.undo
  }

  get undoProcessed() {
    return notificationsStore.undoProcessed
  }

  get foreignMessage() {
    return !!this.selectedMessages?.find((message) => message.data.sender.uid !== this.t2bUser?.id)
  }

  get isUserPresent() {
    const presence = this.selectedTextSession.presence;
    return !!presence && presence.hasOwnProperty(this.userId!!)
  }

  get tourNumber() {
    return tourStore.tourNumber
  }

  onAudioFileSelected(event) {
    console.log(`onAudioRecorderEvent => ${JSON.stringify(event)}`);
    const file = event.target.files[0];
    const fileSize: number = +((file.size / 1024) / 1024).toFixed(4);
    console.log(`onAudioRecorderEvent => file.size = ${JSON.stringify(fileSize)} Mb`);
    const _URL = window.URL || window.webkitURL;
    const url = _URL.createObjectURL(file);
    // Do something with the audio file.
    const player: HTMLAudioElement = this.$refs.audioPlayer as HTMLAudioElement;
    player.src = url;
    console.log(`onAudioRecorderEvent => added player.src = ${url}`);
  }

  async onRecordAudio() {
    this.audioSelected = true;
    await this.initAudioPlayer();
  }

  async onStartVideoCall() {
    try {
      await videoCallStore.startVideoCall(this.selectedTextSession.id)
    } catch (err) {
      console.error(err)
    }
  }

  async onJoinVideoCall() {
    try {
      await videoCallStore.joinVideoCall({
        chatId: this.selectedTextSession?.id,
        videoCallId: this.selectedTextSession.videoCall?.id
      })
    } catch (err) {
      console.error(err)
    }
  }

  switchRequestControl() {
    if (this.chatId && this.undoProcessed.indexOf(this.chatId!) !== -1) {
      this.disableRequestControls = true
      this.rejectRequestSelected = true
    } else {
      this.disableRequestControls = false
      this.rejectRequestSelected = false
    }
  }

  closeDialog() {
    this.disableRequestControls = false;
  }

  showAvatar(index, senderUid) {
    const prevMessage = this.messageItems[index - 1];
    const prevSenderUid = prevMessage && prevMessage?.data?.sender.uid;
    const nextMessage = this.messageItems[index + 1];
    const nextSenderUid = nextMessage && nextMessage?.data?.sender.uid;
    if (!prevSenderUid && !nextSenderUid) {
      return true;
    } else {
      return nextSenderUid !== senderUid;
    }
  }

  messageKind(index, messages) {
    const prevIndex = index - 1
    const nextIndex = index + 1

    const prevMsg = messages[prevIndex]
    const nextMsg = messages[nextIndex]

    const currentUid = messages[index].data.sender.uid;
    const prevUid = prevMsg && prevMsg.data.sender.uid;
    const nextUid = nextMsg && nextMsg.data.sender.uid;

    return [currentUid === prevUid, currentUid === nextUid];
  }

  messageDbClicked(message) {
    if (this.messageToReplay !== message) {
      this.messageToReplay = message;
    } else {
      this.messageToReplay = null;
    }
  }

  async onOptionItemClicked(action, messageView) {
    const message = messageView.data;
    switch (action.type) {
      case ACTION_MESSAGE_DOWNLOAD: {
        this.downloadImage(message);
        break;
      }
      case ACTION_MESSAGE_REPLY: {
        this.messageDbClicked(message);
        break;
      }
      case ACTION_MESSAGE_COPY: {
        try {
          await copyTextToClipboard(message.text);
          this.showInfo('Message has been copied');
        } catch (err) {
          this.showIssue('Failed to copy message');
        }
        break;
      }
      case ACTION_MESSAGE_EDIT: {
        this.chatMessageText = message.text;
        this.messageToEdit = message;
        break;
      }
      case ACTION_MESSAGE_PIN_NOTE: {
        try {
          const result = await chatStore.pinMessageToNote(message);
          if (result) {
            this.showInfo('Note has been created');
          } else {
            this.showIssue('Note has been removed');
          }
        } catch (error: any) {
          this.showIssue(error.message);
        }
        break;
      }
      case ACTION_MESSAGE_FORWARD: {
        this.onForwardMessage(message)
        break;
      }
      case ACTION_MESSAGE_DELETE: {
        messageView.selected = true
        this.showDeleteMessagesDialog = true
        break;
      }
      case ACTION_MESSAGE_MORE: {
        this.moreOption = true;
        break;
      }
      default:
    }
  }

  onForwardMessage(message: any) {
    chatStore.setForwardedMessage(message)
    this.showForwardMessageDialog = true
  }

  onForwardMessages() {
    chatStore.setForwardedMessages(this.selectedMessages.map((item) => item.data))
    this.onCancelSelection()
    this.showForwardMessageDialog = true
  }

  onForwardMessageToChat(chatId: string) {
    this.forwardTrigger = false
    this.$router.push({name: 'active-chat', params: {chatId}, query: {type: 'active', subtype: 'personal'}})
  }

  async onForwardMessageToContact(contactId: string) {
    try {
      const chatId = await this.createInnerChat({contactIds: [contactId]})
      await this.$router.push({name: 'inner-chat', params: {chatId}, query: {type: 'inner', subtype: 'personal'}})
    } catch (err: any) {
      this.showIssue(err.message)
    }
  }

  onCancelForwardMessage() {
    chatStore.clearForwarding()
    this.onCancelSelection()
  }

  onDeleteMessages() {
    this.showDeleteMessagesDialog = true
  }

  @Action deleteMessages

  private async deleteSelectedMessages(forAll: boolean) {
    await this.deleteMessages({
      messageIDs: this.selectedMessages?.map((message) => message.data.id),
      forAll
    })
  }

  onCancelSelection() {
    this.moreOption = false
    this.selectedMessages
        .filter((message) => message.selected)
        .forEach((message) => message.selected = false)
  }

  onCaseNotes() {
    this.chatInfoWindow = 'caseNotes';
    this.drawerChatInfo = true;
  }

  onViewPersonalData() {
    this.chatInfoWindow = 'viewPersonalData';
    this.drawerChatInfo = true;
  }

  async onShowDetailSchedule(appointId) {
    try {
      await requestsStore.loadAppointmentById(appointId);
      this.showDialogDetailSchedule = true;
    } catch (error) {
      console.log(error);
    }
  }

  onChatInfo() {
    this.chatInfoWindow = 'info';
    this.drawerChatInfo = true;
  }

  getLetters(text) {
    return twoChars(text);
  }

  get typing() {
    const presence = this.selectedTextSession?.presence || {};
    const typing = Object.keys(presence)
        .filter((uid) => this.userId !== uid && presence[uid])
        .map((uid) =>
            (this.selectedTextSession.members && this.selectedTextSession.members[uid]) ?
                firstWord(this.selectedTextSession.members[uid].name) : '')
        .join(',');
    return typing ? `${typing} typing...` : this.onlineStatus;
  }

  get onlineStatus(): string {
    const memberUids = Object.keys(this.selectedTextSession?.members || {});
    const membersCount = memberUids.length;
    let onlineCount = 0;
    memberUids.forEach(uid => {
      const member = this.selectedTextSession.members[uid];
      if (member.status && member.status.online) {
        onlineCount++;
      }
    });
    return `${membersCount} members, ${onlineCount} online`;
  }

  get unreadCount(): number {
    const unread = this.selectedTextSession?.unread || {}
    return unread[this.userId!] || 0;
  }

  @Watch('isBlockedUser')
  onIsBlockedUserChanged(after: boolean, _: boolean) {
    if (after) {
      this.dialogConfirmActionTitle = `You have been blocked by ${this.chatTitle}. Do you want to close this case?`
      this.dialogConfirmActionMessage = 'This case will store in the archive'
      this.confirm = 'Yes, close'
      this.onDialogConfirmCancel = async () => {
        await chatStore.clearChatIdForType(this.key)
        await this.$router.push({name: 'active', params: {chatId: ''}, query: this.$route.query});
        this.dialogConfirmAction = false;
      }
      this.onDialogConfirmActionPositiveCallback = () => {
        this.closeCase()
      }
      this.dialogConfirmAction = true;
    } else {
      this.dialogConfirmAction = false;
      this.dialogConfirmActionTitle = ''
      this.dialogConfirmActionMessage = ''
      this.confirm = ''
      this.onDialogConfirmCancel = null
      this.onDialogConfirmActionPositiveCallback = null
    }
  }

  private forwardedMessageType(message: any) {
    return message.data?.forwardedMessage?.type
  }

  private searchMessages(term) {
    if (!term) {
      this.srcMessages = Object.assign([], this.messageItems);
      return;
    }
    const searchString = term.toLowerCase();
    const fFunc = (value) => value.text.toLowerCase().includes(searchString)
        || value.sender.toLowerCase().includes(searchString);
    this.srcMessages = this.messageItems.filter(fFunc);
  }

  // Triggered when a file is selected via the media picker.
  private onMediaFileSelected(event) {
    this.file = event.target.files[0];

    if (this.file) {
      // Clear the selection in the file picker input.
      // imageFormElement.reset();
      // Check if the file is an image.
      if (!this.file.type.match('image.*')) {
        this.error = {
          message: 'You can only send images',
          timeout: 2000
        };
        return;
      }

      this.dialogConfirmActionTitle = 'Do you want send image ?';
      this.dialogConfirmActionMessage = '';
      this.onDialogConfirmCancel = () => {
        this.file = null
      }
      this.onDialogConfirmActionPositiveCallback = async () => {
        this.dialogConfirmAction = false;
        // Check if the user is signed-in
        if (this.t2bUser) {
          const screenWindow: any = window;
          const _URL = screenWindow.URL || screenWindow.webkitURL;
          const img = new Image();
          img.onload = async () => {
            try {
              await this.sendImageMessage({
                src: img.src,
                file: this.file,
                width: img.width,
                height: img.height
              });
              this.file = null
            } catch (error) {
              console.error(
                  'There was an error uploading a file to Cloud Storage:',
                  error
              );
            }
          };
          img.onerror = () => {
            this.showIssue(`not a valid file: ${this.file.type}`);
          };
          img.src = _URL.createObjectURL(this.file);
        }
      }
      this.confirm = 'Yes, send'
      this.dialogConfirmAction = true;
    }
  }

  private async sendMessage(event) {
    if ((event.key === 'Enter' && event.shiftKey) ||
        (event.key === 'Enter' && event.ctrlKey)) {
      return;
    }
    if (this.chatMessageLoading) {
      return;
    }
    if (event.key === 'Enter') {
      this.chatMessageText = this.chatMessageText.substring(0, event.target.selectionStart - 1) +
          this.chatMessageText.substring(event.target.selectionStart)
    }
    this.chatMessageLoading = true
    this.chatMessageText = this.chatMessageText.trim()
    if (!this.chatMessageText.length && !this.messageToForward) {
      this.chatMessageLoading = false
      return;
    }
    try {
      if (this.messageToReplay !== null) {
        await this.sendReplyMessage({
          replyTo: this.messageToReplay,
          text: this.chatMessageText
        });
        this.chatMessageText = '';
        this.chatMessageLoading = false
        this.messageToReplay = null;
        return;
      }
      if (this.messageToEdit !== null) {
        await this.editMessage({
          messageId: this.messageToEdit.id,
          text: this.chatMessageText
        });
        this.chatMessageText = '';
        this.chatMessageLoading = false
        this.messageToEdit = null;
        return;
      }
      if (this.messageToForward !== null) {
        await this.forwardMessage({
          messageToForward: this.messageToForward,
          text: this.chatMessageText
        });
        this.chatMessageText = '';
        this.chatMessageLoading = false
        chatStore.clearForwarding()
        return;
      }
      await this.sendTextMessage(this.chatMessageText);
      this.chatMessageText = '';
      this.chatMessageLoading = false
    } catch (error: any) {
      this.showIssue(error.message);
      this.chatMessageLoading = false
    }
  }

  private canDeleteForAll() {
    const isArray = Array.isArray(this.messageToDelete);
    if (isArray) {
      return !this.messageToDelete.find(
          item => item.sender.uid !== this.t2bUser!.id
      );
    } else {
      return (
          !!this.messageToDelete &&
          this.t2bUser!.id === this.messageToDelete.sender.uid
      );
    }
  }

  private onForwardCase() {
    this.titleForwardCaseDialog = 'Forward case';
    this.showForwardCaseDialog = true;
  }

  private onForwardRequest() {
    this.titleForwardCaseDialog = 'Forward request';
    this.showForwardCaseDialog = true;
  }

  private onCaseForwarded() {
    this.closeConversation()
    this.showInfo('Case has been forwarded')
    this.$emit('request-forwarded')
  }

  private async requestAction(action) {
    this.disableRequestControls = true;
    await action();
    this.disableRequestControls = false;
  }

  private async onRejectRequest() {
    this.showUndo(
        this.selectedTextSession.id,
        'rejected',
        'The request is rejected.',
        {
          id: this.selectedTextSession.id,
          cId: this.selectedTextSession.case.id,
          bId: this.selectedTextSession.case.business.id
        },
        async (data) => {
          console.warn(data)
          const error = await this.rejectRequest({
                cId: data.cId,
                bId: data.bId
              }
          )
          if (!!error) {
            this.showIssue(error)
          } else {
            this.showInfo('Request has been rejected')
            if (this.$router.currentRoute.name === 'inbox-request'
                && this.$router.currentRoute.params.chatId === data.id) {
              await this.$router.push({name: 'inbox'})
            }
          }
        },
        5
    )
  }

  private async onAcceptRequest() {
    this.disableRequestControls = true;
    this.acceptRequestSelected = true;
    const response = await this.acceptRequest();
    if (!!response.error) {
      this.showIssue(response.error);
    } else {
      this.showInfo('Request has been accepted');
      const {type, subtype} = this
      await requestsStore.clearRequestIdForType(`${type}:${subtype}`)
      this.$emit('request-accepted', response.requestId);
    }
    this.acceptRequestSelected = false;
    this.disableRequestControls = false;
  }

  private async onBlockCustomer() {
    this.disableRequestControls = true;
    this.blockCustomerSelected = true;
    const error = await this.blockContact();
    if (!!error) {
      this.showIssue(error);
    }
    this.showInfo('Customer has been blocked');
    this.blockCustomerSelected = false;
    this.disableRequestControls = false;
  }

  private onUnblockUser() {
    this.dialogConfirmActionTitle = 'Do you want to unblock this user?';
    this.dialogConfirmActionMessage = '';
    this.onDialogConfirmActionPositiveCallback = async () => {
      this.dialogConfirmAction = false;
      const error = await this.unblockContact();
      this.setSelectedCustomer(null);
      if (!!error) {
        this.showIssue(error);
        return
      }
      this.showInfo('User has been unblocked');
    }
    this.confirm = 'Yes, unblock'
    this.dialogConfirmAction = true;
  }

  private async closeCase() {
    this.dialogConfirmAction = false;
    try {
      await this.caseClose();
      await chatStore.clearChatIdForType(this.key)
      this.showInfo('Case has been closed');
      await this.$router.push({name: 'active', params: {chatId: ''}, query: this.$route.query});
    } catch (err: any) {
      this.showIssue(err.message);
    }
  }

  private async onShareChat() {
    try {
      const shortLink = await chatStore.shareChat()
      await copyTextToClipboard(shortLink)
      this.showInfo('Chat link copied to clipboard', 'chain')
    } catch (err: any) {
      this.showIssue(err.message)
    }
  }

  private onCloseCase() {
    this.dialogConfirmActionTitle = 'Do you want to close this case?';
    this.dialogConfirmActionMessage =
        'This case will store in archive';
    this.onDialogConfirmActionPositiveCallback = this.closeCase;
    this.confirm = 'Yes, close'
    this.dialogConfirmAction = true;
  }

  private clearMessage() {
    this.chatMessageText = ''
    this.messageToEdit = null
    this.messageToReplay = null
    chatStore.clearForwarding()
  }

  private clearSelectedMessage() {
    this.messageToReplay = null
    this.messageToEdit = null
    this.chatMessageText = ''
    chatStore.clearForwarding()
  }

  private onChooseContact() {
    this.showDialogSendContact = true;
    // this.personalOnly = true;
    // this.contactSelectedCallback = this.onSendContact;
  }

  private async onSendContact(contact) {
    try {
      await this.sendContact(contact)
      this.showInfo('Contact has been sent')
    } catch (error: any) {
      this.showIssue(error.message)
    }
  }

  private onRequestPersonalData() {
    if (this.checkPermissionRequest) {
      if (!this.checkLastPermissionRequest) {
        this.requestPersonalData(this.selectedTextSession)
      }
    } else {
      this.onViewPersonalData()
    }
  }

  private onScheduleAppoint() {
    this.requestAppointment();
    //this.showDialogScheduleAnAppointment = true
  }

  private onChooseImage() {
    const fileField: HTMLInputElement = this.$refs.chooseImageForChat as HTMLInputElement
    fileField.value = ''
    fileField.click()
  }

  private closeLocation() {
    this.showMap = false
    this.location = null;
    this.resetSelectedLocation();
  }

  private showLocation(geopoint: any) {
    console.log('showLocation => geopoint: ' + JSON.stringify(geopoint))
    this.location = {lat: geopoint.latitude, lng: geopoint.longitude};
    this.showMap = true;
  }

  private async sendLocation() {
    if (!!this.selectedLocation) {
      console.log('sendLocation => selectedLocation: ' + JSON.stringify(this.selectedLocation))
      await this.sendLocationMessage();
      this.closeLocation();
    }
  }

  private async onToggleFullConversation(full: boolean) {
    if (!full) {
      this.setFullConversation(false)
      return
    }

    if (full && this.firstMessageCreatedDate.toMillis() < this.caseOpenDate.toMillis()) {
      this.setFullConversation(true)
      return
    }

    if (this.unsubscribeMessages !== null) {
      this.unsubscribeMessages();
    }
    this.unsubscribeMessages = await this.loadMessages({chatId: this.chatId, full});
  }

  private onMessageInputChanged(event) {
    console.log(event)
  }

  private onViewContact(contact: any) {
    if (contact.type <= 2) {
      this.viewContactId = contact.id
    } else {
      this.viewCustomerId = contact.id
    }
  }

  private async init(chatId?: string, type?: string, subtype?: string) {
    const key = ['all', 'personal'].includes(subtype ? subtype : '') ? `${type}:${subtype}` : `${type}:personal`
    if (!chatId) {
      return
    }

    this.unsubscribeTextSession = await this.loadChat({chatId, type: key});

    this.unsubscribeMessages = await this.loadMessages({
      chatId,
      full: this.isInnerChat,
      isPersonal: subtype !== 'all'
    });

    this.unsubscribeCaseNotes = await chatStore.loadCaseNotes();

    if (this.isActiveChat) {
      this.unsubscribeCustomerNotes = await chatStore.loadCustomerNotes();
    }
  }

  private downloadImage(message) {
    const name = message.image.name
    const storageUri = `${message.textSessionId}/${message.id}/${name}`
    downloadFile({name, storageUri})
  }

  private async initAudioPlayer() {
    const player: any = this.$refs.audioPlayer;
    console.log('audioPlayer = ' + player);
    const audioPlayer: any = document.getElementById('audioPlayer');
    console.log('audioPlayer = ' + audioPlayer);
    const handleSuccess = function(stream) {
      if (window.URL) {
        player.srcObject = stream;
        console.log('handleSuccess => added player.srcObject');
      } else {
        player.src = stream;
        console.log('handleSuccess => added player.src');
      }
    };

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({audio: true, video: false});
      const addTrackListener = (event) => {
        console.log(`onAddTrackListener => ${JSON.stringify(event)}`);
      };
      mediaStream.addEventListener('addtrack', addTrackListener);
      // mediaStream.removeEventListener('addtrack', addTrackListener)
      // handleSuccess(mediaStream);
      console.log('media stream setup');
    } catch (e) {
      console.error(e);
    }
  }

  unsubscribeAll() {
    if (this.unsubscribeTextSession) {
      try {
        this.unsubscribeTextSession();
      } catch (e) {
      }
      this.unsubscribeTextSession = null;
    }
    if (this.unsubscribeMessages) {
      try {
        this.unsubscribeMessages();
      } catch (e) {
      }
      this.unsubscribeMessages = null;
    }
    if (this.unsubscribeCaseNotes) {
      try {
        this.unsubscribeCaseNotes();
      } catch (e) {
      }
      this.unsubscribeCaseNotes = null;
    }
    if (this.unsubscribeCustomerNotes) {
      try {
        this.unsubscribeCustomerNotes();
      } catch (e) {
      }
      this.unsubscribeCustomerNotes = null;
    }
  }

  async onFocusIn() {
    if (!this.isUserPresent) {
      await chatStore.updatePresence({presentIn: this.chatId})
    }
  }

  async onFocusOut() {
    await chatStore.updatePresence({notPresentIn: this.chatId})
  }

  created() {
    this.$nextTick(() => {
      this.focusChatContainer()
    })
  }

  updated() {
    this.$nextTick(() => {
      this.focusChatContainer()
      this.switchRequestControl()
    })
  }

  focusChatContainer() {
    document.getElementById('chatContainer')?.focus()
  }

  async beforeRouteEnter(to, from, next) {
    await chatStore.updatePresence({presentIn: to.params.chatId})
    next((vm) => {
      vm.unsubscribeAll()
      vm.init(to.params.chatId, to.query.type, to.query.subtype)
    });
  }

  async beforeRouteUpdate(to, from, next) {
    this.viewContactId = null
    this.viewCustomerId = null
    this.unsubscribeAll()
    // presence toggle
    await chatStore.updatePresence({presentIn: to.params.chatId, notPresentIn: from.params.chatId})
    await this.init(to.params.chatId, to.query.type, to.query.subtype)
    if (this.forwardTrigger) {
      this.clearMessage()
    } else {
      this.forwardTrigger = true
    }
    next();
  }

  async beforeRouteLeave(to, from, next) {
    this.viewContactId = null
    this.viewCustomerId = null
    this.unsubscribeAll()
    this.closeConversation();
    // presence toggle
    await chatStore.updatePresence({notPresentIn: from.params.chatId})
    next()
  }
}
