



























import BaseDialog from '@/components/BaseDialog.vue'
import {Component, Prop, Vue} from 'vue-property-decorator'
import SearchView from '@/components/SearchView.vue'
import Avatar from "@/components/Avatar.vue";
import {channelsStore} from "@/store/modules/channels";

@Component({
  name: 'dialog-subscribers',
  components: {BaseDialog, SearchView, Avatar},
})
export default class DialogSubscribers extends Vue {
  @Prop() value!: boolean
  srcItems?: any[] | null = []

  get show() {
    return this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
  }

  get members() {
    return channelsStore.selectedChannelMembers
  }

  get membersCount() {
    return this.members?.length || 0
  }

  get title() {
    return `${this.membersCount} subscribers`
  }

  private onSearch(term) {
    if (!term) {
      this.srcItems = channelsStore.selectedChannelMembers;
      return;
    }
    const searchString = term.toLowerCase();
    this.srcItems = channelsStore.selectedChannelMembers!!.filter((value) =>
        value.name.toLowerCase().includes(searchString))
  }

  private onClientView(id) {
    this.$router.push({name: 'customer-profile', params: {customerId: id, type: 'all'}});
  }

  private created() {
    this.srcItems = Object.assign([], this.members);
  }
}
