
























import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import ContactsList from '@/components/ContactsList.vue';
import {Action, Getter} from 'vuex-class';
import {BusinessContact} from '@/domain/model/types';
import {applicationStore} from '@/store/modules/application';
import {profileStore} from '@/store/modules/profile';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';
import {requestsStore} from '@/store/modules/requests/RequestsStore';

@Component({
  name: 'dialog-forward-case',
  components: {BaseDialog, ContactsList}
})
export default class DialogForwardCase extends mixins(Vue, Notifications) {
  @Prop() value!: boolean;
  @Prop() title!: string;
  @Prop() subtitle!: string;

  @Getter selectedTextSession;

  selectedContact: BusinessContact | null = null;
  loading: boolean = false

  @Action forwardCase;
  @Action closeConversation;

  get businessId() {
    return applicationStore.businessId;
  }

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  onContactSelected(contact: BusinessContact[]) {
    this.selectedContact = contact[0];
  }

  async onForwardCase() {
    if (!this.selectedContact || !this.selectedContact.id || !this.selectedTextSession || !this.selectedTextSession.case) {
      return;
    }
    this.showUndo(
        this.selectedTextSession.id,
        'forward',
        `The request is forwarded to ${this.selectedContact.name}.`,
        {
          forwardedCase: this.selectedTextSession.case,
          toContactId: this.selectedContact.id
        },
        async (data) => {
          const error = await this.forwardCase(data)
          if (!!error) {
            this.showIssue(error)
          } else {
            this.showInfo('Case has been forwarded')
            if (data.forwardedCase.textSessionId === this.$router.currentRoute.params.chatId) {
              this.closeConversation()
              if (this.$router.currentRoute.name === 'inbox-request') {
                await this.$router.push({name: 'inbox'})
              } else {
                requestsStore.lastRequestRoute('')
                const {type, subtype} = this.$router.currentRoute.query
                await requestsStore.clearRequestIdForType(`${type}:${subtype}`)
                await this.$router.push({name: this.$router.currentRoute.name!, query: this.$router.currentRoute.query})
              }
            }
          }
        }, 5)
    this.show = false;
  }
}
